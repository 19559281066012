@import url('https://fonts.googleapis.com/css2?family=Rowdies&display=swap');

.heading {
    font-family: 'Rowdies', cursive;
    font-size: 3em;
}

.description {
    font-family: 'Nunito', sans-serif;
    margin-top: 0;
}


.reset-button{
    margin:10px;
    background: none;
    font-size: 1em;
    border: 2px solid #fff;
    padding: 6px 12px;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}

.keyboard_launch {
    display: none;
}


@media only screen and (max-width: 600px) {
    .description {
        margin-top: 0;
    }
    .keyboard_launch {
        display: inline;
    }
}