.word {
    display: flex;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .letter {
    border-bottom: 3px solid#99572b;
    display: inline-flex;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
    height: 50px;
    width: 20px;
  }


  @media only screen and (max-width: 600px) {

    .word{
      bottom: 0;
    }
  
  }