* {
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

body {
  background-color: #60a3bc;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  margin: 0;
  overflow: hidden;
}

.main {
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin:40px 20px;
  text-align: left;
}

.menu{
  margin-left: 50px;
}

h1 {
  margin: 20px 0 0;
}

.game-container {
  padding: 20px 30px;
  position: relative;
  margin: auto;
  height: 350px;
  width: 450px;
}

.figure-container {
  fill: transparent;
  stroke: #fff;
  stroke-width: 4px;
  stroke-linecap: round;
}

.figure-part {
  display: none;
}

.wrong-letters-container {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  text-align: right;
}

.wrong-letters-container p {
  margin: 0 0 5px;
}

.wrong-letters-container span {
  font-size: 24px;
}


.popup-container {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* display: flex; */
  display: none;
  align-items: center;
  justify-content: center;
}

.popup {
  font-family: 'Nunito', sans-serif;
  background:#D35400;
  border-radius: 5px;
  box-shadow: 0 15px 10px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.popup.success {
  background-color: #27ae60;
}

.popup .finalRevealWord span{
  border: dashed 0.5px white;
  border-bottom: 3px solid#99572b;
  display: inline-flex;
  padding: 10px;
  font-size: 1.5em;
  border-bottom: dashed 0.5px;
}

.popup button {
  cursor: pointer;
  background-color: #fff;
  color: #2980b9;
  border: 0;
  margin-top: 20px;
  padding: 12px 20px;
  font-size: 16px;
}

.popup button:active {
  transform: scale(0.98);
}

.popup button:focus {
  outline: 0;
}
.mobile-message {
  display: none;
}

@media only screen and (max-width: 600px) {

  .main {
    display: block;
  }

  .main .menu{
    margin: auto;
    text-align: center;
  }

  .game-container {
    height: auto;
    width: auto;
  }

  .word{
    bottom: 0;
  }

  .figure-container {
    margin-bottom: 20px;
  }

  .mobile-message {
    font-family: 'Nunito', sans-serif;
    display: block;
  }
}



footer {
  position: absolute;
  bottom: 5px;
  left: 0; 
  font-family: 'Nunito', sans-serif;
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 400px; 
  font-size: 1em;
  text-align: center;
}

footer a {
  text-decoration: none;
  cursor: pointer;
  color: #7a3201;
}